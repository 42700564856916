.accordion {
    dt {
        cursor: pointer;
        background: $gray-300;
        padding: 20px;
        margin-bottom: 5px;

        &:after {
            content: '+';
            float: right;
        }
        &.active:after {
            content: '-';
        }
    }
    
    dd {
        display: none;
    }
}

