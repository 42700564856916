
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 60px 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0px;
    }
    &.less-pad {
        padding: 40px 0
    }
    &.large-pad {
        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 160px 0;
        }
    }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.6;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-preview-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    
    iframe,
    object,
    embed,
    img.poster,
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

	.play-button {
        width: 100px;
		position: absolute;
		left: calc(50% - 50px);
		top: calc(50% - 40px);
		padding: 0;
		margin: 0;
		border: none;
		appearance: none;
		background: none;
		z-index: 5;
		transition: .8s ease;

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&:focus {
			outline: 0;
		}
	}
}

img {
    width: 100%;
    &.logo-design {
        width: 120px;
    }
}
.max-width-image {
    width: 100%;
    max-width: 400px;
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }
}
.min-width-image-xl {
    @include media-breakpoint-up(xl) {
        min-width: 600px;
    }
    @include media-breakpoint-up(xml) {
        min-width: 700px;
    }
}

.container-fluid {
    max-width: 1750px;
}

.color {
    &-primary {
        color: $primary;
    }
    &-secondary {
        color: $secondary;
    }
}
.bg {
    &-light {
        background: #f7f8fa !important;
    }
    &-white {
        box-shadow: 0px 16px 100px 20px rgba(108, 108, 108, 0.1);
    }
}
