// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    margin-bottom: 12px;
    font-size: 2.65rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 20px 5px;
    white-space: normal;
    background: $primary;
    color: #fff;
    text-shadow: 0px 7px 6px rgba(40, 40, 40, 0.18);
    transition: background-color .4s ease-in-out;
    @include media-breakpoint-up(md) {
        padding: 30px 10px;
    }
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background: rgba($primary, .8);
    }
}

.btn-section {
    p,
    small {
        width: 90%;
        margin: 0 auto;
    }
}