// --------------------
// VARIABLES
//---------------------

$headerheight   : 150px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 200px;
$header-scrolled-width: 160px;

// Colors
$body-color		: #3f3f3f;
$headings-color: #333;
$primary        : #d3bca3;
$secondary      : #2c4568;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$headerbg       : #f1f1f1; // Header scrolled background color.

$bg-table-active-color : $primary;

$slider-title-color : #ffffff;
$slick-dot-color : #ffffff;
$slick-active-dot-color : $primary;

// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xml: 1240px
  );
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 769px,
	lg: 992px,
  xl: 1140px,
  xml: 1450px,
  xxl: 1600px,
);
