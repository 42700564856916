section {
    &#hero,
    &#ebook-hero {
        padding-top: 120px;
        @include media-breakpoint-up(md) {
            padding-top: 160px;
        }
        @include media-breakpoint-up(xxl) {
            padding-top: 200px;
        }
        .offer {
            background: rgba(#fff, .32);
            border-radius: 4px;
            padding: 10px 30px;
            padding: 20px 50px 16px;
            line-height: 1;
        }
    }
    &#featured-in {
        background: rgba(#19283c, .8);
        .slide  {
            .icon {
                width: 36px;
            }
            span {
                font-weight: 300;
            }
        }
    }
    &#section-1 {
        @include media-breakpoint-up(md) {
            .award-3 {
                height: 70px;
                width: auto;
            }
        }
    }
    &#section-3,
    &#section-10,
    &#last-section {
        padding-bottom: 25%;
        .background-image-container img {
            object-fit: contain;
            object-position: center bottom;
            @include media-breakpoint-up(xml) {
                object-fit: cover;
            }
        }
    }

    &#section-3 {
        .bg-white {
            padding: 4rem 3rem;
            @include media-breakpoint-up(md) {
                padding: 6rem 4rem;
            }
            @include media-breakpoint-up(lg) {
                padding: 6rem;
            }
        }
    }
    &#section-5 {
        .slide {
            @include media-breakpoint-up(md) {
                @include make-col-ready();
                @include make-col(3);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(2.4);
            }
        }
    }
    &#section-7 {
        background: #eaf2f5;
        .background-image-container {
            right: -10%;
            width: 70%;
            @include media-breakpoint-up(xl) {
                width: 80%;
                top: -10%;
                height: 120%;
            }
        }
        .icon-stars {
            width: 180px;
        }
        p {
            font-size: 2.15rem;
        }
    }
    &#section-9 {
        .icon-guarantee {
            width: 300px;
        }
    }

    &#section-10,
    &#last-section {
        .background-image-container img {
            object-fit: contain;
        }
    }
    &#last-section {
        padding-bottom: 40%;
    }
}