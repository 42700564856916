
ul, ol {
	&.styled-list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
            margin-bottom: 4rem;
			display: block;
        }
	}
}

ul {
    &.styled-list {
        li {
			img {
				width: 40px;
				min-width: 25px;
				@include media-breakpoint-up(md) {
					min-width: 40px;
				}
			}
        }
	}
}

ol {
	&.styled-list {
		counter-reset: ol-counter;
		li {
			position: relative;
			counter-increment: ol-counter;
			padding: 3rem;
			padding-left: 8rem;
			&::before {
				content: counter(ol-counter);
				position: absolute;
				top: 3rem;
				left: 1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				@extend h3;
				width: 5.5rem;
				height: 5.5rem;
				line-height: 5.5rem;
				text-align: center;
				border-radius: 50%;
				color: white;
				@extend .bg-secondary;
				box-shadow: -0.872px 9.962px 19px 0px rgba(56, 78, 86, 0.25);
			}
			@include media-breakpoint-up(md) {
				padding: 5rem 3rem;
				padding-left: 12rem;
				&:before {
					top: 5rem;
					left: 3rem;
					width: 6.5rem;
					height: 6.5rem;
					line-height: 6.5rem;
				}
			}
		}
	}
}
