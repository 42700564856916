header {
    padding: 15px;
    background-color: $headerbg;
    
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
    }
}

.header-logo img {
    transition: width 0.2s ease-in-out;

    // Default to small scrolled size on mobile 
    width: $header-scrolled-width;

    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
        width: $header-starting-width;
    }
    .scrolled & {
        // Turn on the header resize on desktop
        @include media-breakpoint-up(md) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2; 
    .phone-icon {
        width: 50px;
        background: white;
        border-radius: 30px;
        padding: 12px;
        img,
        svg {
            width: 100%;
        }
    }

    // Got questions (if it exists)
    span {
        font-size: 1.65rem;
        padding-left: 3px;
        margin-bottom: 4px;
    }
    // Phone number text
    em {
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
    }

} 