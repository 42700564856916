// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'Gill Sans';
    src: url('../../fonts/GillSans.woff2') format('woff2'),
    url('../../fonts/GillSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../fonts/GillSans-Light.woff2') format('woff2'),
    url('../../fonts/GillSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../fonts/GillSans-Medium.woff2') format('woff2'),
    url('../../fonts/GillSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../../fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$headings-font: 'Montserrat-Bold', sans-serif;
$body-font: 'Gill Sans', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    // @include font-smoothing('on');
    font-size: 2rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: 1.4;
    font-family: $body-font;
    font-weight: normal;
}

p {
    letter-spacing: 1px;
    margin-bottom: 3rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 3rem;
    letter-spacing: 1px;
    font-family: $headings-font;
    color: $headings-color;
}

h1, .h1 {
    @extend .text-uppercase;
    font-size: 3.5rem;
    @include media-breakpoint-up(lg) {
        font-size: 4rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 4.75rem;
    }
}

h2, .h2 {
    @extend .text-uppercase;
    font-size: 3rem;
    @include media-breakpoint-up(lg) {
        font-size: 3.6rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 4rem;
    }
}

h3, .h3 {
    font-size: 3.25rem;
}

h4, .h4 {
    font-size: 2.15rem;
    font-family: $body-font;
    font-weight: bold;
    color: $secondary;
    letter-spacing: 2px;
}

h5, .h5 {
    font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

small {
    font-size: 1.5rem;
}
strong {
    font-weight: bold;
}
p.large {
    font-size: 2.25rem;
}
.text-underline {
    text-decoration: underline;
}

.border-bottom-center,
.border-bottom-left {
    position: relative;
    padding-bottom: 2rem;
    text-align: center;
    &::after {
        content: '';
        position: absolute;
        left: calc(50% - 55px);
        bottom: 0;
        width: 110px;
        height: 1px;
        background: $secondary;
    }
}

.border-bottom-left {
    @include media-breakpoint-up(lg) {
        text-align: left;
        &:after {
            left: 0;
        }
    }
}