/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    min-height: 1px;

    p {
        max-width: 70%;
        margin: 0 auto 3rem;
    }
    h4 {
        color: $slider-title-color;
        margin-bottom: 0;
        text-transform: uppercase;
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


// Custom Slick Styles

// Arrows
.slick-arrow {
    position: absolute;
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;

    img,
    svg {
        width: 15px;
    }
    &.prev,
    &.prev-arrow {
        left: 0;
    }
    &.next,
    &.next-arrow {
        right: 0;
    }
    @include media-breakpoint-up(lg) {
        width: 90px;
        height: 90px;
        img,
        svg {
            width: 18px;
        }
        &.prev,
        &.prev-arrow {
            left: -10px;
        }
        &.next,
        &.next-arrow {
            right: -10px;
        }
    }
    
}


// Dots
.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 2rem 0 0;
    list-style-type: none;

    li {
        margin: 0 0.25rem;
    }

    button {
        display: block;
        width: 2rem;
        height: 2rem;
        padding: 0;
        margin-bottom: 0;
        border: 2px solid $slick-active-dot-color;
        border-radius: 50%;
        background-color: $slick-dot-color !important;
        text-indent: -9999px;
    }

    li.slick-active button {
        background-color: $slick-active-dot-color !important;
    }
}