form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        box-shadow: 0px 27px 35px 0px rgba(181, 181, 181, 0.16);
        border: none;
        margin-bottom: 15px;
        border-radius: 0;
        font-size: 2rem;
        line-height: 1;
        color: $secondary;
        background: white;
        #last-section & {
            background: transparent;
        }
        @include media-breakpoint-up(md) {
            padding: 30px 15px 30px 70px;
        }
    }

    textarea {
        height: 210px !important;
    }

    select {
        appearance: none;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        &.error {
            display: block;
            margin-bottom: 20px;
            margin-top: -10px;
            color: red;
            font-size: 1.9rem;
        }
        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            width: 20px;
            z-index: 999;
            margin: auto;
            &.icon-email {
                width: 24px;
            }
            
            @include media-breakpoint-up(md) {
                left: 25px;
                top: 30px;
            }
        }
    }
}

.form-group {
    width: 100%;
}