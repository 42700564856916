#steps-block {
    .steps .col-md-6 {
        @include media-breakpoint-up(lg) {
            padding-bottom: 2rem;
        }
    }
    .step {
        padding: 3rem;
        &-number {
            width: 8rem;
            height: 8rem;
            line-height: 8rem;
            border-radius: 50%;
        }
        @include media-breakpoint-up(lg) {
            padding: 5rem 3rem;
            &-number {
                width: 11rem;
                height: 11rem;
                line-height: 11rem;
            }
        }
        @include media-breakpoint-up(xl) {
            padding: 6rem 5rem;
        }
    }
}